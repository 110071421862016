body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BrainTree Widget CSS */
#card-input .braintree-form__hosted-field {
  border-radius: 6px;
}

#card-input .braintree-methods--active .braintree-method--active,
#card-input .braintree-sheet--active .braintree-sheet{
  border-color: #21bfaf;
}

#card-input .braintree-method--active .braintree-method__check {
  background-color: #21bfaf;
}
// Colors
@primary-color: #21bfaf;
@border-color-split: #e2e2e2;

// Global
@border-radius-base: 12px;
@border-radius-sm: 8px;
@border-radius-xs: 6px;

// Button
@btn-border-radius-base: @border-radius-xs;

// Classes
.ant-input {
  border-radius: @border-radius-xs;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: @border-radius-xs;
}

.ant-radio-button-wrapper:first-child {
    border-radius:  @border-radius-xs 0 0  @border-radius-xs;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0  @border-radius-xs  @border-radius-xs 0;
}

.ant-btn {
  min-width: 70px;
  font-weight: 500;
}

.ant-alert {
  border-radius: @border-radius-xs;
}

.ant-dropdown-menu {
  overflow: hidden;
  border-radius: @border-radius-sm;
}

.ant-picker {
  border-radius: @border-radius-xs;
}

.ant-tag {
  border-radius: @border-radius-xs;
}

.ant-input-affix-wrapper {
  border-radius: @border-radius-xs;
}

.ant-checkbox-inner {
  border-radius: 4px;
}
